import "bundles/core";
import "bundles/video/drmDetect";
import "shared/sentry.io-video";
import AdobeAuthorizationService from "mvpd/AdobeAuthorizationService"
import FreeWheelFMS from 'services/FreeWheelFMS';
import SmartTagPlayer from "video/smart-tag-player";

window.CBSPlayer = {
    FreeWheelFMS: FreeWheelFMS,
    SmartTagPlayer(playerParams) {
        SmartTagPlayer.call(this, playerParams, (smartTag, smartTagPlayerInstance) => {
        // TODO: Test and remove url check if not needed
        if (!window.location.href.includes("/live-tv/stream")) {
            if (CBS.Registry.user.sub_status === "MVPD_AUTHZ") {
                let adobeAuthorizationService = new AdobeAuthorizationService({
                    isLiveTV: false,
                    metaData: smartTagPlayerInstance.metaData,
                    tms_program_id: smartTagPlayerInstance.tms_program_id
                })
                if (adobeAuthorizationService.playerPromiseToWaitFor) {
                    smartTag.addPromiseToWaitFor(
                        adobeAuthorizationService.playerPromiseToWaitFor
                    )
                }
            }
        }
        })
    }
};

window.onkeydown = function(e) {
    return !(e.keyCode == 32 && e.target == document.body);
};

