import HttpService from 'services/HttpService';
import axios from 'axios';
import {getMVPDCMObj, enableCMVOD, enableCMLiveTV, mvpdOptions, setMetaDataStatus} from "helpers/mvpdStorage";

export default class AdobeCMService {
    constructor(config = {}) {
        this.config = config
        this.http = new HttpService();

        this.heartbeatTimer = null;
        this.mvpd_cm = getMVPDCMObj() || {}
        this.setupDidSetAllMetadataPromise()
        this.sessionID = null
        const self = this;
        window.addEventListener('beforeunload', async (e) => {
            delete e['returnValue'];
            await self.deleteSession(false);
        });
    }

    get isLiveTV() {
        return this.config.isLiveTV
    }

    get enableCM() {
        let enableCM = false

        if (this.isLiveTV)  {
            enableCM = enableCMLiveTV(mvpdOptions())
        } else {
            enableCM = enableCMVOD(mvpdOptions())
        }
        return enableCM
    }

    static get requiredMetaData() {
        return [
            'mvpd',
            'hba_status',
            'upstreamUserID',
            'householdID',
        ]
    }

    static get HEARTBEAT_URL() {
        const domain = (CBS.Registry.sparrowHost) ? CBS.Registry.sparrowHost : 'https://sparrow.cbs.com';

        return `${domain}/streamer/adobe/cm/session/heartbeat.json`;
    }

    setupDidSetAllMetadataPromise() {
        this.__didSetMetadata = {}

        AdobeCMService.requiredMetaData.forEach((key) => {
            this.__didSetMetadata[key] = false
        })

        this.__resolveDidSetAllMetadata = null;

        this.didSetAllMetadata = new Promise((resolve, reject) => {
            this.__resolveDidSetAllMetadata = resolve
        })
    }

    /**
     * @param displayMessageDelegate
     */
    delegateDisplayMessageTo(displayMessageDelegate) {
        if (displayMessageDelegate &&
            typeof displayMessageDelegate === 'object' &&
            typeof displayMessageDelegate.displayMessage === 'function') {

            this.displayMessageDelegate = displayMessageDelegate
        }
    }


    /**
     * @param key
     * @param encrypted
     * @param data
     * @private
     */
    onSetMetaDataStatus(key, encrypted, data) {
        setMetaDataStatus(key, data)

        this.__didSetMetadata[key] = true

        if (Object.values(this.__didSetMetadata).every((didSet) => didSet === true)) {
            this.mvpd_cm = getMVPDCMObj()
            this.__resolveDidSetAllMetadata()
        }
    }


    /**
     * @param ae { expect window.ae or AdobeEnabler.ae }
     */
    onAuthenticated(ae) {
        if (ae) {
            ae.getMetadata('mvpd');
            ae.getMetadata('hba_status');
            ae.getMetadata('upstreamUserID');
            ae.getMetadata('householdID');
        }
    }


    startMonitoring() {
        this.initSession()
    }


    /**
     * @returns {string}
     */
    getHBA_Status() {
        if (this.enableCM) {
            return (this.mvpd_cm.hba_status).toString()
        }

        return 'true'
    }


    /**
     * Starting stream monitor
     */
    async initSession() {
        try {
            await this.didSetAllMetadata
            if (this.enableCM) {
                let url = `/live-tv/stream/tveverywhere/mvpd/cm/${this.mvpd_cm.idp}/${this.mvpd_cm.subject}/init/`;

                let resp = await this.http.doPost(url, {
                    hba_status: this.getHBA_Status(),
                    mobileDevice: true,
                })

                let {success, result} = resp.data
                if (success && result.headers.Location) {
                    this.sessionID = result.headers.Location

                    this.heartbeat();
                } else {
                    throw new Error('initSession failed')
                }
            }
        } catch (e) {
            this.deleteSession()
        }
    }


    /**
     * Heartbeat ping in an interval to check maximum stream limit exceeding
     */
    async heartbeat() {
        try {
            await this.didSetAllMetadata

            let data = {
                platform: 'desktop',
                idp: this.mvpd_cm.idp,
                sessionid: this.sessionID,
                subject: this.mvpd_cm.subject,
            };

            let params = new FormData();

            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    params.append(key, data[key]);
                }
            }

            let resp = await axios.post(AdobeCMService.HEARTBEAT_URL, params, {
                headers: {
                    common: {
                        'Accept': 'application/json'
                    },
                    post: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            })

            let { success, headers, status } = resp.data

            if (success) {
                let { reasonPhrase, statusCode } = status

                if (reasonPhrase === 'Accepted' && statusCode === 202) {
                    this.setupNextHeartbeat(headers)
                    return
                } else if (reasonPhrase === 'Gone' && statusCode === 410) {
                    this.initSession()
                    return
                }
            }

            throw new Error('heartbeat failed')
        } catch (e) {
            this.deleteSession()
        }
    }


    /**
     * @param headers
     */
    setupNextHeartbeat(headers) {
        if (!headers.Expires || !headers.Date) {
            this.deleteSession()
            return
        }

        //Set cushion to call heartbeat early
        const lessTimeCushion = 100
        const timeToNextHeartbeat = new Date(headers.Expires) - new Date(headers.Date) - lessTimeCushion
        this.heartbeatTimer = setTimeout(async () => {
            await this.heartbeat()
        }, timeToNextHeartbeat)
    }


    /**
     * Terminate stream monitor
     *
     * @param displayMessage
     * @returns {Promise<void>}
     */
    async deleteSession(displayMessage = true) {
        if (this.heartbeatTimer) {
            clearTimeout(this.heartbeatTimer);
        }

        if (displayMessage && this.enableCM) {
            this.displayStreamExceedLimitMessage()
        }

        let session = this.sessionID

        if (session) {
            this.sessionID = null

            try {
                await this.didSetAllMetadata

                let url = `/live-tv/stream/tveverywhere/mvpd/cm/${this.mvpd_cm.idp}/${this.mvpd_cm.subject}/${session}/delete/`;

                const resp = await this.http.doPost(url, {})

                if (resp.success) {
                    window.localStorage.removeItem('mvpd_cm');
                }
            } catch (e) {
            }
        }
    }

    displayStreamExceedLimitMessage() {
        let templateVars = {
            title: 'You\'ve reached the maximum number of simultaneous video streams for your account.',
            description: 'To view this video, close the other videos you\'re watching and try again.',
            reloadCTA: 'Try again'
        }

        this.displayMessageDelegate.displayMessage(templateVars)
    }
}
