import {isMVPDComcast} from "helpers/mvpdStorage";

export default class ContentAuthorization {
    get promise() {
        return this._authorizationPromise
    }

    get metaData() {
        if (this.config && typeof this.config === 'object') {
            return this.config.metaData
        }

        return {}
    }

    get assetID() {
        if (this.config && typeof this.config === 'object') {
            return this.config.tms_program_id
        }

        return null
    }

    get assetTitle() {
        return this.metaData.videoTitle
    }

    get ratingScheme() {
        return this.metaData.mediaType === 'Movie' ? 'urn:mpaa' : 'urn:v-chip'
    }

    get contentRating() {
        if (Array.isArray(this.metaData.regionalRatings) &&
            this.metaData.regionalRatings.length > 0) {

            let regionalRating = this.metaData.regionalRatings[0]

            if (regionalRating && typeof regionalRating === 'object') {
                return regionalRating.rating
            }
        }

        return this.metaData.rating
    }

    /**
     * @param config
     */
    constructor(config = {}) {
        this.config = config
        /**
         * @type {null|function}
         * @private
         */
        this._authorizationPromiseResolve = null
        /**
         * @type {null|function}
         * @private
         */
        this._authorizationPromiseReject = null

        /**
         * @type {null|Promise}
         * @private
         */
        this._authorizationPromise = new Promise((resolve, reject) => {
            this._authorizationPromiseResolve = resolve
            this._authorizationPromiseReject = reject
        })
    }

    get isLiveTV() {
        return this.config.isLiveTV
    }

    /**
     * @returns {string}
     */
    getRSSResource() {
        if (this.isLiveTV) {
            return this.getRSSResourceLiveTV()
        }

        let resource = `
            <rss version="2.0" xmlns:media="http://search.yahoo.com/mrss/">
                <channel>
                    <title>CBS_ENTERTAINMENT</title>
                    <item>
                        <title>${ this.assetTitle }</title>
                        <guid>${ this.assetID }</guid>
                        <media:rating scheme="${ this.ratingScheme }">${ this.contentRating }</media:rating>
                    </item>
                </channel>
            </rss>
        `

        return resource.trim()
    }

    getRSSResourceLiveTV() {
        let rating = 'TV-14';

        let resource = `
            <rss version="2.0" xmlns:media="http://search.yahoo.com/mrss/">
                <channel>
                    <title>CBS_ENTERTAINMENT</title>
                    <item>
                        <title>LiveTV</title>
                        <media:rating scheme="urn:v-chip">${rating}</media:rating>
                    </item>
                </channel>
            </rss>
        `

        return resource.trim()
    }

    /**
     * @param ae { expect window.ae or AdobeEnabler.ae }
     */
    onAuthenticated(ae) {
        if (ae) {
            ae.getAuthorization(this.getRSSResource())
        }
    }

    onNotAuthenticated() {
        this._authorizationPromiseReject()

        this.displayContentBlockMessage(this._defaultErrorTemplateVars())
    }

    onSetToken() {
        this._authorizationPromiseResolve()
    }

    onTokenRequestFailed(inRequestedResourceID, inRequestErrorCode, inRequestErrorDetails) {
        this._authorizationPromiseReject()

        let templateVars = this._mapErrorDetailsToTemplateVars(inRequestErrorCode, inRequestErrorDetails)

        if (!this._validateTemplateVars(templateVars)) {
            templateVars = this._defaultErrorTemplateVars()
        }

        this.displayContentBlockMessage(templateVars)
    }


    /**
     * @param displayMessageDelegate
     */
    delegateDisplayMessageTo(displayMessageDelegate) {
        if (displayMessageDelegate &&
            typeof displayMessageDelegate === 'object' &&
            typeof displayMessageDelegate.displayMessage === 'function') {

            this.displayMessageDelegate = displayMessageDelegate
        }
    }

    /**
     * @param templateArgs
     */
    displayContentBlockMessage(templateArgs = {}) {
        if (this.displayMessageDelegate) {
            this.displayMessageDelegate.displayMessage(templateArgs)
        }
    }

    /**
     * @returns {boolean}
     */
    static isRequired() {
        return CBS.Registry.user.sub_status === 'MVPD_AUTHZ' && isMVPDComcast()
    }

    /**
     * @param templateVars
     * @returns {boolean}
     */
    _validateTemplateVars(templateVars) {
        if (typeof templateVars.title === 'string' && templateVars.title.length === 0) {
            templateVars.title = null
        }

        if (typeof templateVars.description === 'string' && templateVars.description.length === 0) {
            templateVars.description = null
        }

        if (!Boolean(templateVars.title) && !Boolean(templateVars.description)) {
            return false
        }

        if (this._isAllowedErrorMessage(templateVars.title) &&
            this._isAllowedErrorMessage(templateVars.description) &&
            this._isAllowedURL(templateVars.url)) {

            return true
        }

        return false
    }

    /**
     * Note: intended use for messages from adobe errorDetails
     *
     * @param message
     * @returns {boolean}
     * @private
     */
    _isAllowedErrorMessage(message) {
        if (!message) {
            return true
        }

        if (typeof message === 'string') {
            if (isMVPDComcast()) {
                const allowedMessages = [
                    'This network is not included in your current television subscription.Please contact your TV provider to subscribe',
                    'Your Parental Control settings do not allow you to view this network.',
                    'Your Parental Control settings do not allow you to view this content.',
                    'The Parental Control service is experiencing technical difficulties.  Please try again later.',
                ]

                return allowedMessages.includes(message)
            }
        }

        return false
    }

    /**
     * Note: intended use for urls from adobe errorDetails
     *
     * @param url
     * @returns {boolean}
     * @private
     */
    _isAllowedURL(url) {
        if (!url) {
            return true
        }

        if (isMVPDComcast()) {
            let allowedURLs = [
                'https://customer.stg.xfinity.com/Secure/OnlineParentalControls.aspx',
                'https://customer.xfinity.com/#/settings/parental-controls',
                'https://customer.xfinity.com/Secure/OnlineParentalControls.aspx',
            ]

            return allowedURLs.includes(url)
        }

        return false
    }

    /**
     * @param errorCode
     * @param errorDetails
     * @returns {{closeCTA: string, title: *, url: *, urlCTA: string}|{description: *, title: *}}
     * @private
     */
    _mapErrorDetailsToTemplateVars(errorCode, errorDetails) {
        if (isMVPDComcast() && typeof errorDetails === 'string') {
            let details = errorDetails.split("\n")

            let title = details[0].trim()
            let url = (details.length > 1) ? details[1].trim() : null

            return {
                title,
                url,
                urlCTA: 'More Info',
                closeCTA: 'OK',
            }
        }

        return {
            title: errorCode,
            description: errorDetails,
        }
    }

    /**
     * @returns {{reloadCTA: string, closeCTA: string, title: string}}
     * @private
     */
    _defaultErrorTemplateVars() {
        return {
            title: 'Please make sure you\'re connected, and try refreshing the page.',
            closeCTA: 'OK',
            reloadCTA: 'Try again'
        }
    }
}
