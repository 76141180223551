import LiveStreamAvailabiltyService from 'components/livetv/LiveStreamAvailabilityService'
import LiveTVAvailabilityResponse from 'components/livetv/LiveTVAvailabilityResponse'
import LiveTVStreamInfoResponse from "components/livetv/LiveTVStreamInfoResponse";
import {getMVPDConfig, getMVPDName} from 'helpers/mvpdStorage'
import {getCookie} from 'helpers/VanillaCookie'
import mvpdUnavailableTemplate from "templates/liveTV/_messageMVPDUnavailable.tpl";
import mvpdNotSupportedTemplate from "templates/liveTV/_messageMVPDNotSupported.tpl";
import MessageBox from "components/messageBoxes/BaseLiveTVMessage";

const STREAM_INFO_MSG = {
    MVPD: 'MVPD',
    UPSELL: 'UPSELL',
}

const AVAILABILITY_MSG = {
    EMPTY_MVPDS: 'EMPTY_MVPDS',
    AA_ONLY: 'AA_ONLY',
}

export default class MVPDLiveStreamAvailabilityService extends LiveStreamAvailabiltyService {
    get AVAILABILITY_ENDPOINT() {
        return this.constructor.ENDPOINT.AVAILABILITY_ENDPOINT
    }

    get STREAM_INFO_ENDPOINT() {
        return this.constructor.ENDPOINT.STREAM_INFO_ENDPOINT
    }

    /**
     * @returns {Promise<LiveTVAvailabilityResponse>}
     * @private
     */
    async __requestAvailability() {
        const resp = await this.http.doPost(this.AVAILABILITY_ENDPOINT, {
            lat: this.location.coords.latitude,
            long: this.location.coords.longitude,
            mvpd_name: getMVPDName(),
        })

        return new LiveTVAvailabilityResponse(resp.data)
    }

    /**
     * Use in __requestAvailability for testing during development
     * @param resp
     */
    mockAvailability(resp) {
        if (this.mockAllowed) {
            const mock = (new URLSearchParams(window.location.search)).get('MOCK_AVAILABILITY')

            if (mock) {
                if ((mock === AVAILABILITY_MSG.EMPTY_MVPDS) || (mock === AVAILABILITY_MSG.AA_ONLY)) {
                    resp.data.message = mock
                    resp.data.success = true
                } else {
                    super.mockAvailability(resp)
                }
            }
        }
    }

    __onAvailabilityError(availabilityMessage) {
        if (availabilityMessage === AVAILABILITY_MSG.EMPTY_MVPDS) {
            this.__onMVPDUnavailable()
        } else if (availabilityMessage === AVAILABILITY_MSG.AA_ONLY) {
            this.__onAAContentOnlyHandler()
        } else {
            super.__onAvailabilityError(availabilityMessage)
        }
    }

    /**
     * Case: Live TV Streaming is not setup in the area
     * @private
     */
    __onMVPDUnavailable() {
        this.tracking.trackMVPDAvailabilityStatus('unavailable', this.__getSelectedProvider());

        this.mvpdUnavailableMessageBox = new MessageBox(this.streamContainer, mvpdUnavailableTemplate({
            title: this.messages.getMessage('lt_no_coverage_title'),
            desc: this.messages.getMessage('lt_no_coverage_desc')
        }));

        this.mvpdUnavailableMessageBox.show();
    }

    /**
     * Case: Content is for AllAccess only, check if user is currently logged in as AA,
     * otherwise show TV Provider does not have the rights to the station in this area
     * @param resp
     * @private
     */
    __onAAContentOnlyHandler() {
        this.tracking.trackMVPDAvailabilityStatus('notsupported', this.__getSelectedProvider());

        this.mvpdNotSupportedMessageBox = new MessageBox(this.streamContainer, mvpdNotSupportedTemplate({
            title: this.messages.getMessage('lt_not_supported_title'),
            desc: this.messages.getMessage('lt_not_supported_desc'),
            list: this.messages.getMessage('lt_not_supported_desc_list'),
            cta: this.messages.getMessage('lt_not_supported_cta'),
            cta_url: this.messages.getMessage('lt_not_supported_cta_url'),
            signin_info: this.messages.getMessage('lt_not_supported_signin_info'),
            signin: this.messages.getMessage('lt_not_supported_signin'),
            signin_url: this.messages.getMessage('lt_not_supported_signin_url')
        }));

        this.mvpdNotSupportedMessageBox.show();
    }

    async __requestStreamInfo(station) {
        let payload = {
            lat: this.location.coords.latitude,
            long: this.location.coords.longitude,
            station: station.station,
            media: station.media,
            mvpd_name: getMVPDName(),
            mvpd_authz: this.__getMVPDAuthToken(),
            tk_vid: this.token,
        };

        const resp = await this.http.doPost(this.STREAM_INFO_ENDPOINT, payload)

        return new LiveTVStreamInfoResponse(resp.data)
    }

    __onStreamInfoError(streamInfoMessage) {
        if (streamInfoMessage === STREAM_INFO_MSG.UPSELL) {
            this.__onStreamInfoFailedNotLoggedIn();
        } else if (streamInfoMessage === STREAM_INFO_MSG.MVPD) {
            this.__onStreamInfoFailedMVPD();
        } else {
            super.__onStreamInfoError(streamInfoMessage)
        }
    }

    /**
     * MVPD verified but user is not logged in yet, send them back to mvpd flow
     * @private
     */
    __onStreamInfoFailedNotLoggedIn() {
        window.location.assign('/live-tv/stream/tveverywhere/')
    }

    /**
     * MVPD auth token failed, restart process for mvpd
     * @private
     */
    __onStreamInfoFailedMVPD() {
        window.location.assign('/live-tv/stream/tveverywhere/')
    }

    /**
     * Retrieving MVPD authz session token
     * @returns {null}
     * @private
     */
    __getMVPDAuthToken() {
        let mvpdAuthz = getCookie('mvpd_authz');
        return mvpdAuthz ? mvpdAuthz : null;
    }

    __getSelectedProvider() {
        const providerConfig = getMVPDConfig(getMVPDName())

        return {
            providerID: providerConfig?.adobe_id,
            providerName: providerConfig?.mvpd,
        }
    }
}
