module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div id=\"slate__content__block\" class=\"slate\">\n    <div class=\"slate__wrapper\" aria-live=\"polite\">\n        ")
    ;  if (typeof title === 'string') { 
    ; __append("\n        <div class=\"slate__title\" role=\"status\">")
    ; __append(escapeFn( title ))
    ; __append("</div>\n        ")
    ;  } 
    ; __append("\n\n        ")
    ;  if (typeof description === 'string') { 
    ; __append("\n        <div class=\"slate__description\" role=\"status\" aria-live=\"polite\" aria-atomic=\"true\">\n            <p>")
    ; __append(escapeFn( description ))
    ; __append("</p>\n        </div>\n        ")
    ;  } 
    ; __append("\n\n        <div class=\"slate__cta-wrapper\">\n            ")
    ;  if (typeof closeCTA === 'string') { 
    ; __append("\n            <button class=\"button qt-okclosebtn\">\n                <div class=\"button__text\">")
    ; __append(escapeFn( closeCTA ))
    ; __append("</div>\n            </button>\n            ")
    ;  } 
    ; __append("\n\n            ")
    ;  if (typeof url === 'string' && typeof urlCTA === 'string') { 
    ; __append("\n            <a class=\"button qt-moreinfo-btn\" href=\"")
    ; __append(escapeFn( url ))
    ; __append("\" target=\"_blank\" rel=\"noopener\">\n                <div class=\"button__text\">")
    ; __append(escapeFn( urlCTA ))
    ; __append("</div>\n            </a>\n            ")
    ;  } 
    ; __append("\n\n            ")
    ;  if (typeof reloadCTA === 'string') { 
    ; __append("\n            <button class=\"button\" onclick=\"location.reload(true);\">\n                <div class=\"button__text\">")
    ; __append(escapeFn( reloadCTA ))
    ; __append("</div>\n            </button>\n            ")
    ;  } 
    ; __append("\n        </div>\n    </div>\n\n</div>\n")
  }
  return __output;

}