export default class AdobeEnabler {

    constructor() {
        this.ae = null;

        this.config = {
            requestorID: 'CBS',
            resourceID: 'CBS_ENTERTAINMENT'
        };

        this.onSetAuthenticationStatusCallback = null;
        this.onDisplayProviderDialogCallback = null;
        this.onCreateIframeCallback = null;
        this.onSetTokenCallback = null;
        this.onTokenRequestFailedCallback = null;
        this.onSetMetaDataStatusCallback = null;
        this.onSendTrackingDataCallback = null;
    }

    init() {
        this.asyncLoadAEScript();
        this.exposeGlobalCallbacks();
    }

    onSetAuthenticationStatus(callback) {
        this.onSetAuthenticationStatusCallback = callback;
    }

    onDisplayProviderDialog(callback) {
        this.onDisplayProviderDialogCallback = callback;
    }

    onCreateIframe(callback) {
        this.onCreateIframeCallback = callback;
    }

    onSetToken(callback) {
        this.onSetTokenCallback = callback;
    }

    onTokenRequestFailed(callback) {
        this.onTokenRequestFailedCallback = callback;
    }

    onSetMetaDataStatus(callback) {
        this.onSetMetaDataStatusCallback = callback;
    }

    onSendTrackingData(callback) {
        this.onSendTrackingDataCallback = callback;
    }

    exposeGlobalCallbacks() {
        window.entitlementLoaded = () => {
            this.__onEntitlementLoaded();
        };

        window.setConfig = (configuration) => {
        }

        window.selectedProvider = (providerResponse) => {
        }

        window.preauthorizedResources = (authorizedResources) => {
        }

        window.setAuthenticationStatus = (isAuthenticated, errorCode) => {
            if (typeof this.onSetAuthenticationStatusCallback === 'function') {
                this.onSetAuthenticationStatusCallback(isAuthenticated, errorCode);
            } else {
                this.__onSetAuthenticationStatus(isAuthenticated, errorCode);
            }
        };

        window.displayProviderDialog = (providers) => {
            if (typeof this.onDisplayProviderDialogCallback === 'function') {
                this.onDisplayProviderDialogCallback(providers);
            }
        };

        window.createIFrame = (inWidth, inHeight) => {
            if (typeof this.onCreateIframeCallback === 'function') {
                this.onCreateIframeCallback(inWidth, inHeight);
            }
        };

        window.setToken = (inRequestedResourceID, inToken) => {
            if (typeof this.onSetTokenCallback === 'function') {
                this.onSetTokenCallback(inRequestedResourceID, inToken);
            }
        };

        window.setMetadataStatus = (key, encrypted, data) => {
            if (typeof this.onSetMetaDataStatusCallback === 'function') {
                this.onSetMetaDataStatusCallback(key, encrypted, data);
            }
        };

        window.sendTrackingData = (trackingEventType, trackingData) => {
            if (typeof this.onSendTrackingDataCallback === 'function') {
                this.onSendTrackingDataCallback(trackingEventType, trackingData);
            }
        };

        window.tokenRequestFailed = (inRequestedResourceID, inRequestErrorCode, inRequestErrorDetails) => {
            if (typeof this.onTokenRequestFailedCallback === 'function') {
                this.onTokenRequestFailedCallback(inRequestedResourceID, inRequestErrorCode, inRequestErrorDetails)
            }
        }
    }

    /**
     * Proxy call set selected provider
     * @param providerId
     */
    setSelectedProvider(providerId) {
        this.ae.setSelectedProvider(providerId);
    }

    /**
     * Async loading adobe's Access Enabler script
     */
    asyncLoadAEScript() {
        getScript(CBS.Registry.mvpdAE.js, () => {
            this.__onAEScriptLoaded();
        });
    }

    /**
     * @private
     */
    __onAEScriptLoaded() {
        // event hook for AE script loaded
        window.ae = new window.Adobe.AccessEnabler(CBS.Registry.mvpdAE.ss);
    }

    __onEntitlementLoaded() {
        this.ae = window.ae;

        // according to adobe docs, this must be called before making any API calls
        let config = {
            backgroundLogout: true
        };
        this.ae.setRequestor(this.config.requestorID, null, config);

        // if this is not a new session, flush out Adobe session first
        if (!this.__isNewSession()) {
            this.ae.logout();
        }

        this.ae.checkAuthentication();  // will trigger `setAuthenticationStatus` callback
    }

    /**
     * @param {Boolean} isAuthenticated
     * @param {String} errorCode
     * @private
     */
    __onSetAuthenticationStatus(isAuthenticated, errorCode) {
        if (isAuthenticated === 1) {
            this.ae.checkAuthorization(this.config.resourceID);

            this.ae.getMetadata('mvpd');
            this.ae.getMetadata('hba_status');
            this.ae.getMetadata('upstreamUserID');
            this.ae.getMetadata('maxRating');
            this.ae.getMetadata('householdID')
        } else {
            this.ae.getAuthentication();    // will trigger `displayProviderDialog` callback
        }
    }

    __isNewSession() {
        let logoutSession = window.localStorage.getItem('cbs_logout');

        if (logoutSession !== null) {
            window.localStorage.removeItem('cbs_logout');
            return false;
        }

        return true;
    }

}

/**
 * Static helper function for fetching script asynchronously
 * @param {String} source
 * @param {Function} callback
 */
const getScript = (source, callback) => {
    var script = document.createElement('script');
    var prior = document.getElementsByTagName('script')[0];
    script.async = 1;

    script.onload = script.onreadystatechange = (_, isAbort) => {
        if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
            script.onload = script.onreadystatechange = null;
            script = undefined;
            if (!isAbort) if (callback) callback();
        }
    };

    script.src = source;
    prior.parentNode.insertBefore(script, prior);
};
