module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div id=\"slate__mvpd__unavailable\" class=\"slate\">\n    <div class=\"slate__wrapper\">\n        <div class=\"slate__title\">")
    ; __append(escapeFn( title ))
    ; __append("</div>\n        <div class=\"slate__description\">\n            <p>")
    ; __append(escapeFn( desc ))
    ; __append("</p>\n        </div>\n    </div>\n</div>")
  }
  return __output;

}