import ContentAuthorization from "mvpd/ContentAuthorization";
import AdobeEnabler from "components/livetv/AdobeEnabler";
import AdobeCMService from "mvpd/AdobeCMService";
import ContentBlockMessageBox from "components/messageBoxes/ContentBlockMessageBox";
import MVPDLiveStreamAvailabilityService from "./MVPDLiveStreamAvailabilityService";
import { mvpdOptions, isConcurrencyMonitoringRequired } from "helpers/mvpdStorage";

export default class AdobeAuthorizationService {
    constructor(config = {}) {
        this.config = config
        this.requireParentalControls = ContentAuthorization.isRequired()
        this.requireConcurrencyMonitoring = isConcurrencyMonitoringRequired(mvpdOptions())
        this.init()
    }

    get parentalControlsConfig() {
        return {
            isLiveTV: this.config.isLiveTV,
            metaData: this.config.metaData,
            tms_program_id: this.config.tms_program_id,
        }
    }

    get concurrencyMonitoringConfig() {
        return {
            isLiveTV: this.config.isLiveTV
        }
    }

    init() {
        if (this.requireParentalControls) {
            this.parentalControlsService = new ContentAuthorization(this.parentalControlsConfig)
            this.parentalControlsService.delegateDisplayMessageTo(this)
            this.playerPromiseToWaitFor = this.parentalControlsService.promise
        }

        if (this.requireConcurrencyMonitoring) {
            this.concurrencyMonitoringService = new AdobeCMService(this.concurrencyMonitoringConfig)
            this.concurrencyMonitoringService.delegateDisplayMessageTo(this)
            this.concurrencyMonitoringService.startMonitoring()
        }

        if (this.requireParentalControls ||
            this.requireConcurrencyMonitoring) {

            this.__initAdobeEnabler()
        }
    }

    liveStreamAvailabilityServiceFactory(element) {
        return new MVPDLiveStreamAvailabilityService(element)
    }

    onAuthenticated() {
        if (this.requireParentalControls) {
            this.parentalControlsService.onAuthenticated(this.AdobeEnabler.ae)
        }

        if (this.requireConcurrencyMonitoring) {
            this.concurrencyMonitoringService.onAuthenticated(this.AdobeEnabler.ae)
        }
    }

    onNotAuthenticated() {
        if (this.requireParentalControls) {
            this.parentalControlsService.onNotAuthenticated(this.AdobeEnabler.ae)
        }
    }

    /**
     * @param key
     * @param encrypted
     * @param data
     */
    onSetMetaDataStatus(key, encrypted, data) {
        if (this.requireConcurrencyMonitoring) {
            this.concurrencyMonitoringService.onSetMetaDataStatus(key, encrypted, data)
        }
    }

    /**
     * @param inRequestedResourceID
     * @param inToken
     */
    onSetToken(inRequestedResourceID, inToken) {
        if (this.requireParentalControls) {
            this.parentalControlsService.onSetToken(inRequestedResourceID, inToken)
        }
    }

    /**
     * @param inRequestedResourceID
     * @param inRequestErrorCode
     * @param inRequestErrorDetails
     */
    onTokenRequestFailed(inRequestedResourceID, inRequestErrorCode, inRequestErrorDetails) {
        if (this.requireParentalControls) {
            this.parentalControlsService.onTokenRequestFailed(inRequestedResourceID, inRequestErrorCode, inRequestErrorDetails)
        }
    }

    /**
     * @private
     */
    __initAdobeEnabler() {
        this.AdobeEnabler = new AdobeEnabler()

        this.AdobeEnabler.onSetAuthenticationStatus((isAuthenticated, errorCode) => {
            if (isAuthenticated === 1) {
                this.onAuthenticated()
            } else {
                this.onNotAuthenticated()
            }
        })

        this.AdobeEnabler.onSetMetaDataStatus((key, encrypted, data) => {
            this.onSetMetaDataStatus(key, encrypted, data)
        })

        this.AdobeEnabler.onSetToken((inRequestedResourceID, inToken) => {
            this.onSetToken(inRequestedResourceID, inToken)
        })

        this.AdobeEnabler.onTokenRequestFailed((inRequestedResourceID, inRequestErrorCode, inRequestErrorDetails) => {
            this.onTokenRequestFailed(inRequestedResourceID, inRequestErrorCode, inRequestErrorDetails)
        })

        this.AdobeEnabler.init()
    }

    displayMessage(templateVars = {}) {
        if (this.messageBox) {
            this.messageBox.close()
        }

        let playerWrapper = document.querySelector('.video__player-wrapper')

        playerWrapper.innerHTML = ''

        this.messageBox = ContentBlockMessageBox.create(playerWrapper, templateVars)
        this.messageBox.onClose = () => {
            this.messageBox = null
        }

        this.messageBox.show()

        this.concurrencyMonitoringService.deleteSession(false)
    }
}
