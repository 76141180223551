import BaseLiveTVMessage from "components/messageBoxes/BaseLiveTVMessage"
import messageMVPDContentBlock from "templates/liveTV/_messageMVPDContentBlock.tpl"

export default class ContentBlockMessageBox extends BaseLiveTVMessage {
    get onClose() {
        return this._onClose
    }

    set onClose(_onClose) {
        if (typeof _onClose === 'function') {
            this._onClose = _onClose
        }
    }

    /**
     * Uses standard template and takes templateVars
     *
     * @param parentContainer
     * @param templateVars
     * @returns {ContentBlockMessageBox}
     */
    static create(parentContainer, templateVars) {
        const template = messageMVPDContentBlock(templateVars)

        return new ContentBlockMessageBox(parentContainer, template)
    }

    /**
     * @param parentContainer
     * @param template
     */
    constructor(parentContainer, template) {
        super(parentContainer, template)
        this._onClose = null
    }

    show() {
        super.show()

        this.bindEvents()
    }

    bindEvents() {
        let closeButton = this.me.querySelector('.qt-okclosebtn')

        if (closeButton) {
            this.me.querySelector('.qt-okclosebtn').addEventListener('click', (e) => {
                e.preventDefault()
                this.close()
            })
        }
    }

    close() {
        super.close()

        if (this.onClose) {
            this.onClose()
        }
    }
}